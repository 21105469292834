import { GetHotelBookingQuery, TCancellationPenalties } from '@sim-admin-frontends/data-access';
import {
  FormInput,
  FormItemLabel,
  FormSectionHeader,
  FormTextarea,
  SwitchableFormSection,
} from '@sim-admin-frontends/ui-shared';
import {
  formatISODate,
  DATE_FORMAT_SHORT_WITH_TIME,
  diffInMilliseconds,
  formatTableDateTime,
} from '@sim-admin-frontends/utils-shared';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const getCancellationPolicyText = (cancellationPenalties: TCancellationPenalties, t: TFunction) => {
  if (!cancellationPenalties) {
    return '';
  }

  const { freeCancellationBefore, policies } = cancellationPenalties;
  let text = '';

  if (freeCancellationBefore) {
    text += `${t('bookings.freeCancellation', {
      date: formatISODate(freeCancellationBefore, DATE_FORMAT_SHORT_WITH_TIME),
    })} `;
  }

  const policyWithDate = policies
    .filter((policy) => policy.startAt && +policy.amountShow > 0)
    .sort((a, b) => diffInMilliseconds(a.startAt || '', b.startAt || ''))[0];

  if (policyWithDate) {
    text += `${t('bookings.cancelledAfter', {
      date: formatISODate(policyWithDate.startAt || '', DATE_FORMAT_SHORT_WITH_TIME),
      cost: policyWithDate.showAmountWithCommission,
    })} `;
    return text.trim();
  }

  const fallbackPolicy = policies.find((policy) => +policy.amountShow > 0);
  if (fallbackPolicy) {
    text += `${t('bookings.cancellationCost', {
      cost: fallbackPolicy.showAmountWithCommission,
    })} `;
  }

  return text.trim();
};

const SwitchableSectionContentWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacing16};
`;

const SwitchableWrapper = styled.div`
  width: 100%;
`;

type Props = {
  hotelBooking?: GetHotelBookingQuery['getHotelBooking'];
};

const PaymentEditHotelBooking = ({ hotelBooking }: Props) => {
  const { t } = useTranslation();
  if (!hotelBooking) {
    return null;
  }

  const { checkinAt, checkoutAt, guests, hotel, room, price, showAmountToBeRefunded } =
    hotelBooking;
  const { showAmount, showCurrencyCode, showServiceFee, showTotalAmount, showWithCommission } =
    price;

  const cancellationPenalties = room.rate.cancellationPenalties;

  const cancellationPolicyText = cancellationPenalties
    ? getCancellationPolicyText(cancellationPenalties, t)
    : undefined;

  return (
    <>
      <FormSectionHeader title={t('payments.bookingDetails')} />
      <FormInput label={t('payments.hotelId')} value={hotel.id} readOnly disabled />
      <FormInput label={t('payments.hotelName')} value={hotel.name} readOnly disabled />
      <FormInput label={t('payments.roomName')} value={room.roomName} readOnly disabled />
      <FormInput
        label={t('payments.checkIn')}
        value={formatTableDateTime(checkinAt)}
        readOnly
        disabled
      />
      <FormInput
        label={t('payments.checkoutAt')}
        value={formatTableDateTime(checkoutAt)}
        readOnly
        disabled
      />
      {!!showAmountToBeRefunded && (
        <FormInput
          label={t('payments.amountToBeRefunded')}
          value={showAmountToBeRefunded}
          readOnly
          disabled
        />
      )}
      <FormTextarea
        label={t('payments.cancellationPolicy')}
        value={cancellationPolicyText}
        readOnly
        disabled
      />
      <SwitchableWrapper>
        <SwitchableFormSection title={t('payments.prices')} initiallyOpened={false}>
          <SwitchableSectionContentWrapper>
            <FormInput label={t('payments.amount')} value={showAmount} readOnly disabled />
            <FormInput label={t('payments.currency')} value={showCurrencyCode} readOnly disabled />
            <FormInput label={t('payments.serviceFee')} value={showServiceFee} readOnly disabled />
            <FormInput
              label={t('payments.amountWithCommission')}
              value={showWithCommission}
              readOnly
              disabled
            />
            <FormInput
              label={t('payments.totalAmount')}
              value={showTotalAmount}
              readOnly
              disabled
            />
            <FormItemLabel>{t('payments.totalAmountDescription')}</FormItemLabel>
          </SwitchableSectionContentWrapper>
        </SwitchableFormSection>
      </SwitchableWrapper>
      {guests?.map(({ adults, children }) => (
        <FormInput
          key={`${adults}-${children.length}`}
          label={t('payments.guests')}
          value={`${t('payments.adults')}: ${adults}, ${t('payments.children')}: ${
            children.length
          }`}
          readOnly
          disabled
        />
      ))}
    </>
  );
};

export default PaymentEditHotelBooking;
